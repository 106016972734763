import React, { useEffect, useState } from "react"

import cn from "classnames"
import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import DetailsOverlay from "../DetailsOverlay/DetailsOverlay"
import DotsTagsList from "~/components/DotsTagsList/DotsTagsList"
import FullscreenCarouselModal from "~/components/FullscreenCarouselModal/FullscreenCarouselModal/FullscreenCarouselModal"
import SEO from "~/components/Seo/Seo"
import { Body, Container, Heading } from "~/components/ui"
import VideoPlayer from "~/components/VideoPlayer/VideoPlayer"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { Episode, Show } from "~/models/Show"
import withTemplate, {
  TemplateDefaultPropTypes,
} from "~/templates/withTemplate"

import css from "./ShowListing.module.scss"

interface PropTypes extends TemplateDefaultPropTypes {
  data: ShowListingQuery
}

function ShowListing({ data, location }: PropTypes) {
  const intl = useIntl()

  const { show, episodes } = data

  const isCompact = useIsOnCompact()

  const [openIndex, setOpenIndex] = useState<number | undefined>(undefined)

  const allSeasonsNumber = [
    ...new Set(episodes.nodes.map(item => item.seasonNumber)),
  ]

  const filteredSeasons = allSeasonsNumber.map(seasonNumber => {
    return episodes.nodes.filter(item => item.seasonNumber === seasonNumber)
  })

  const [selectedSeason, setSelectedSeason] = React.useState(
    allSeasonsNumber.length - 1
  )

  const hasEpisodes = episodes.nodes.length > 0

  //Open modal if hash param is set
  useEffect(() => {
    const hash = location.hash
    if (hash) {
      const episodeSlug = hash.replace("#", "")
      const episode = episodes.nodes.find(
        (episode: Episode) => episode.slug == episodeSlug
      )

      if (episode) {
        const seasonIndex = allSeasonsNumber.indexOf(episode.seasonNumber)
        setSelectedSeason(seasonIndex)
        const episodeIndex = filteredSeasons[seasonIndex].indexOf(episode)
        setOpenIndex(episodeIndex)
      }
    }
  }, [location.hash])

  return (
    <div className={css.root}>
      <SEO
        title={data.show.seoTitle ?? `Chefclub - Show : ${data.show.title}`}
        location={location}
        description={data.show.synopsis}
        image={data.show.showPosterThumbnail}
      />
      {hasEpisodes && (
        <div style={{ zIndex: 10000 }}>
          <FullscreenCarouselModal
            isOpen={openIndex !== undefined}
            closeModal={() => setOpenIndex(undefined)}
            posts={filteredSeasons[selectedSeason]}
            mediaIndex={openIndex}
            openInFullscreen
            autoplay
            accentColor={show.mainColor ?? "var(--original-color)"}
            swipeHint
          />
        </div>
      )}
      <Container>
        <div className={css.banner}>
          <div className={css.headerVideo}>
            <VideoPlayer
              src={show.hostedVideoUrl}
              poster={show.trailerPoster}
              autoPlay
              verticalColor="white"
              format="other"
              ratio={isCompact ? 400 / 375 : 400 / 452}
              videoFit="cover"
              disableControls
              loop
            />
            <div className={css.overlay}></div>
          </div>
          <section className={css.headline}>
            <h1 className={css.logoContainer}>
              <img
                src={show.showLogo}
                color="white"
                className={css.logo}
                alt={show.title}
              />
            </h1>
            <DotsTagsList
              tags={show.tags}
              mainColor={show.mainColor ?? undefined}
              className={css.tagsList}
            />
            <Body
              color="white"
              variant="body1"
              bold
              className={css.showBaseline}
            >
              {show.baseline}
            </Body>
            <Body color="white" variant="body3" className={css.showSynopsis}>
              {show.synopsis}
            </Body>
          </section>
        </div>
        {!hasEpisodes && (
          <section>
            <div className={css.filters}>
              <div className={cn(css.filter, css.comingSoon)}>
                <Body color="white" variant="body2" bold align="center">
                  {intl.formatMessage({ id: "show/text:coming-soon" })}
                </Body>
              </div>
            </div>
          </section>
        )}
        {hasEpisodes && (
          <section>
            <div className={css.filters}>
              {filteredSeasons.map((season, index) => {
                return (
                  <div
                    key={index}
                    className={css.filter}
                    style={
                      index == selectedSeason
                        ? {
                            backgroundColor:
                              show.mainColor ?? "rgba(255, 255, 255, 0.5)",
                          }
                        : { backgroundColor: "rgba(255, 255, 255, 0.15)" }
                    }
                    onClick={() => setSelectedSeason(index)}
                  >
                    <Body
                      color="white"
                      variant="body2"
                      bold={index == selectedSeason}
                      align="center"
                    >
                      {allSeasonsNumber.length > 1 && isCompact
                        ? `${intl.formatMessage({
                            id: "show/text:season-1-letter-short",
                          })}${allSeasonsNumber[index]}`
                        : `${intl.formatMessage({ id: "show/text:season" })} ${
                            allSeasonsNumber[index]
                          }`}
                    </Body>
                  </div>
                )
              })}
            </div>
            <div className={css.episodes}>
              {filteredSeasons[selectedSeason]?.map((episode, index) => (
                <div
                  key={index}
                  className={css.episode}
                  onClick={() => {
                    setOpenIndex(index)
                  }}
                >
                  <div className={css.episodePoster}>
                    <img
                      src={episode.videoPosterThumbnail}
                      alt={episode.title}
                      className={css.poster}
                    />
                    <DetailsOverlay episode={episode} align="space-between" />
                  </div>
                  <Heading
                    color="white"
                    variant="extraSmall"
                    bold
                    className={css.episodeName}
                  >
                    {episode.title}
                  </Heading>
                </div>
              ))}
            </div>
          </section>
        )}
      </Container>
    </div>
  )
  //
}

interface ShowListingQuery {
  show: Show
  episodes: {
    nodes: Episode[]
  }
}

export const query = graphql`
  query ShowListingPage($id: String) {
    show: show(id: { eq: $id }) {
      ...Show
    }
    episodes: allEpisode(
      sort: { fields: [season_number, episode_number], order: [ASC, ASC] }
      filter: { show: { eq: $id } }
    ) {
      nodes {
        ...Episode
      }
    }
  }
`

export default withTemplate(ShowListing)
