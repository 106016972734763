import React, { CSSProperties } from "react"

import { useIntl } from "react-intl"

import { Body } from "~/components/ui"
import { Episode } from "~/models/Show"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./DetailsOverlay.module.scss"

interface PropTypes {
  episode: Episode
  height?: number
  align: "left" | "right" | "center" | "space-between"
  showName?: string
}
const DetailsOverlay = ({ episode, height, align, showName }: PropTypes) => {
  const intl = useIntl()

  return (
    <>
      <div
        className={css.details}
        style={height ? { height: `${height}px` } : {}}
      >
        <div className={css.content}>
          <div
            className={css.heading}
            style={
              {
                "--align-mobile": align,
              } as CSSProperties
            }
          >
            {showName ? (
              <Body
                variant="body4"
                semiBold
                color="var(--gray3-color)"
                uppercase
              >
                {showName}
              </Body>
            ) : (
              <>
                <Body variant="body4" semiBold className={css.episodeNumber}>
                  {`${intl.formatMessage({ id: "show/text:episode" })} ${
                    episode.episodeNumber
                  }`}
                </Body>
                <Body variant="body4" className={css.episodeDuration}>
                  {new Date(episode.videoDuration * 1000)
                    .toISOString()
                    .substring(14, 19)}
                </Body>
              </>
            )}
          </div>
          <Body
            variant="body4"
            color="white"
            align="left"
            className={css.synopsis}
          >
            {episode.synopsis}
          </Body>
        </div>
      </div>
    </>
  )
}

export default DetailsOverlay
